import React, { useEffect, useState } from 'react';
import { Button, Carousel, CloseButton, Image, Modal } from 'react-bootstrap';
import VideoPlayer, { VideoPlayerProps } from 'components/common/VideoPlayer';
import Error500 from 'components/errors/Error500';
import { FileId } from 'apis/types';
import useFileLoader from 'components/wizard/hooks/useFileLoader';
import useArray from 'hooks/useArray';
import Skeleton from 'react-loading-skeleton';
import PageLoader from 'components/common/PageLoader';
type Attachment = {
  id?: string | number;
  image?: string;
  src?: string;
  title?: string;
  date?: string;
  type?: string;
  fileId?: FileId;
};
const ModalVideoContent = ({
  show,
  setShow,
  attachment: __attachment,
  forceWatch,
  autoplay,
  children,
  galleryIndex,
  ...rest
}: {
  show: boolean;
  setShow: any;
  attachment?: Attachment | Attachment[];
  forceWatch?: boolean;
  galleryIndex?: number;
} & Partial<VideoPlayerProps>) => {
  const attachment = useArray(__attachment);
  const handleEnded = () => setShow(false);
  const [error, setError] = React.useState<boolean>(false);
  const { files, isLoading } = useFileLoader({
    value: attachment?.map(a => a.fileId)
  });
  const [index, setIndex] = useState(0);
  useEffect(() => {
    if (galleryIndex !== undefined) {
      setIndex(galleryIndex);
    }
  }, [galleryIndex]);
  const handleSelect = selectedIndex => {
    setIndex(selectedIndex);
  };
  return (
    <Modal
      show={show}
      size="xl"
      onHide={() => setShow(false)}
      className="video-popup overflow-hidden"
      contentClassName="bg-transparent border-0"
      backdropClassName="video-popup-backdrop"
      backdrop={forceWatch ? 'static' : undefined}
      centered
    >
      {!forceWatch && (
        <CloseButton
          variant="white"
          onClick={() => setShow(false)}
          className="video-popup-close-btn"
        />
      )}
      <Modal.Body
        className="p-0 rounded overflow-auto"
        style={{ maxWidth: '90vw', maxHeight: '90vh' }}
      >
        {error ? (
          <Error500
            button={
              <Button
                onClick={() => {
                  setError(null);
                  setShow(false);
                }}
              >
                Close
              </Button>
            }
          />
        ) : (
          children || (
            <Carousel activeIndex={index} onSelect={handleSelect}>
              {attachment.map((att, i) => (
                <Carousel.Item key={i}>
                  {!att.src ? (
                    <div>
                      {isLoading ? (
                        <div
                          className="w-100 d-flex flex-center"
                          style={{
                            borderRadius: 'var(--falcon-border-radius-lg)'
                          }}
                        >
                          <PageLoader height={'10'} />
                        </div>
                      ) : (
                        <Image
                          className="w-100"
                          style={{
                            borderRadius: 'var(--falcon-border-radius-lg)'
                          }}
                          src={files?.[i]?.preview || att.image}
                        />
                      )}
                    </div>
                  ) : (
                    <VideoPlayer
                      light={att.image}
                      controls={!forceWatch}
                      onEnded={handleEnded}
                      url={att.src}
                      autoplay={forceWatch || autoplay}
                      onError={() => setError(true)}
                      {...rest}
                    />
                  )}
                </Carousel.Item>
              ))}
            </Carousel>
          )
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalVideoContent;
