import { faTimesCircle, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDCheck } from 'apis/flex/hr';
import classNames from 'classnames';
import PdfOverlayer from 'components/app/documents/contracts/PdfOverlayer';
import Expandable from 'components/common/Expandable';
import IconButton from 'components/common/IconButton';
import JSONLister from 'components/common/JSONLister';
import PageLoader from 'components/common/PageLoader';
import Redirect from 'components/common/Redirect';
import FileImage from 'components/files/FileImage';
import FilePdfViewer from 'components/files/FilePdfViewer';
import SmallCheck from 'components/wizard/SmallCheck';
import useUrlSearchParams from 'hooks/useUrlSearchParams';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

export const useOneIdRedirectData = () => {
  const [data, setData] = useState<Record<string, any>>();
  const urlParams = useUrlSearchParams();
  useEffect(() => {
    console.log('checking for code in urlParams', urlParams);
    if (urlParams?.code && !data) {
      setData(r => ({ ...r, extId: urlParams.code }));
    }
    if (urlParams?.error && !data) {
      setData(r => ({ ...r, error: urlParams.error }));
    }
    // if (urlParams?.state) {
    //   const parsed = JSON.parse(urlParams.state);
    //   const { checkId, ...rest } = parsed;
    //   setData(r => ({ ...r, ...rest }));
    // }
  }, [urlParams]);
  return data;
};

const OneIdIFrame = ({
  onDone,
  onError = null,
  checkId
}: {
  onDone: (data: { accessCode: string }) => void;
  onError?: (error: Error) => void;
  checkId: number;
}) => {
  // const listener = (e: MessageEvent) => {
  //   if (!e.data) return;
  //   if (e.data?.source !== 'oneid-redirect') return;
  //   if (e.data.error instanceof Error) {
  //     return onError?.(e.data.error);
  //   }
  //   return onDone(e.data.data);
  // };
  // useEffect(() => {
  //   window?.addEventListener('message', listener);
  //   return () => {
  //     window?.removeEventListener('message', listener);
  //   };
  // }, []);
  const url = `${process.env.REACT_APP_ONEID_URL}/v2/authorize?client_id=${process.env.REACT_APP_ONEID_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ONEID_REDIRECT_URI}&response_type=code&scope=rtw&state=${checkId}`;
  return (
    <Redirect
      message={`We're redirecting you to OneID to verify your identity. Don't worry, you'll be sent back here when you're done!`}
      seconds={4}
      url={url}
    />
  );
  // return useMemo(
  // //   () => (
  // //     <iframe
  // //       className="w-100"
  // //       style={{ height: '60vh' }}
  // //       src={url}
  // //     />
  // //   ),
  // //   [checkId]
  // // );
};
const Review = () => {
  const vals = useWatch<IDCheck>();
  const fileIds = vals.fileIds;
  return (
    <div className="d-flex flex-wrap mt-3 gap-3 justify-content-around w-100">
      {!fileIds?.length && (
        <div className="mt-3 fs--1">
          Awaiting report. Check back in a few minutes...
        </div>
      )}
      {fileIds?.map(fileid => {
        const ext = fileid.split('.').pop();
        if (ext !== 'pdf') {
          return (
            <div key={fileid} className="border rounded p-3">
              <h6>ID</h6>
              <FileImage width={'100%'} fileId={fileid} className="mb-auto" />
            </div>
          );
        } else {
          return (
            <div className="border rounded p-3" key={fileid}>
              <h6>Report</h6>
              <FilePdfViewer
                width={Math.min(window.innerWidth * 0.6, 500)}
                fileId={fileid}
                className="mb-auto"
              />
            </div>
          );
        }
      })}
      {vals.extDetails && (
        <Expandable
          label={d => (d ? 'Hide' : 'Show') + ' details'}
          activeCount={Object.keys(vals.extDetails || {}).length}
        >
          <JSONLister json={vals.extDetails} />
        </Expandable>
      )}
    </div>
  );
};
export default ({
  review,
  check,
  revertToManual
}: {
  check?: IDCheck;
  review?: boolean;
  revertToManual?: () => void;
}) => {
  const { setError, clearErrors, getValues, setValue } = useFormContext();
  const data = useOneIdRedirectData();
  useEffect(() => {
    if (data?.extId && !getValues('extId')) {
      setValue('extId', data.extId);
      clearErrors('extId');
    }
    if (data?.error) {
      setValue('extId', '');
      setError('extId', { message: data.error });
    }
  }, [data]);
  return (
    // <Card>
    //   <Card.Body>
    <Controller
      name="extId"
      rules={{ required: true }}
      render={({ field, fieldState, formState }) => {
        return (
          <>
            <div
              ref={field.ref}
              className={classNames('form-control', {
                'border-0 shadow-none': !Object.keys(formState.errors).length,
                'is-invalid': fieldState.error,
                'is-valid':
                  Object.keys(formState.errors).length > 0 && !fieldState.error
              })}
            >
              {field?.value || fieldState?.error ? (
                <>
                  <div>
                    Verification {fieldState?.error ? 'failed' : 'completed'}{' '}
                    {fieldState?.error ? (
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="text-danger"
                      />
                    ) : (
                      <SmallCheck size="md" className="ms-2" />
                    )}
                  </div>
                  {review ? (
                    <Review />
                  ) : (
                    !!fieldState?.error && (
                      <div className="fs--1 mt-3">
                        Having issues with OneID? Swap to the{' '}
                        <Button
                          variant="link"
                          className="p-0"
                          onClick={revertToManual}
                          size="sm"
                        >
                          manual check
                        </Button>{' '}
                        instead. Or{' '}
                        <Button
                          variant="link"
                          className="p-0"
                          size="sm"
                          onClick={() => {
                            field.onChange('');
                            clearErrors('extId');
                          }}
                        >
                          try again.
                        </Button>
                      </div>
                    )
                  )}
                </>
              ) : review ? (
                <Review />
              ) : (
                <div>
                  <OneIdIFrame
                    checkId={check.id}
                    onError={e => setError('extId', { message: e.message })}
                    onDone={d => {
                      field.onChange(d.accessCode);
                    }}
                  />
                </div>
              )}
            </div>
            {fieldState.error && (
              <Form.Control.Feedback type="invalid">
                {fieldState.error.message}
              </Form.Control.Feedback>
            )}
          </>
        );
      }}
    />
    //   </Card.Body>
    // </Card>
  );
};
